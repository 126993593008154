.login-container {
	// background: url('../../images/loginpage_background.jpg') no-repeat center;
	background-size: cover;
	text-align: center;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
}

.login-logo-container {
	display: inline-block;
	margin-top: 6.25em;
	margin-bottom: 3.15em;
}
