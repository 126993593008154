#root {
	height: 100%;
	width: 100%;
}

.main-content {
	margin-top: $topNavHeight;
	margin-left: $drawerWidth;
	padding: 4 * $spacingUnit;
	// overflow-y: auto;
}

.flex-row {
	display: flex;
	justify-content: space-between;
	margin: 2 * $spacingUnit 0;
	padding: $spacingUnit 0;
}

.flex-col {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.flex-item {
	margin: 0px $spacingUnit;
	width: 100%;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.flex-col-item {
	margin: 4 * $spacingUnit 0;
	width: 100%;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.button-row {
	margin-top: 2 * $spacingUnit;
}

.theme-link {
	color: $dig-red;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		color: $dig-red-2;
	}

	&:focus {
		color: $dig-red-2;
	}
}

.theme-icon {
	color: $dig-red;

	&:hover {
		color: $dig-red;
	}
}

.error-text {
	color: $red-error;
}

.card {
	padding: 2 * $spacingUnit;
}

.card__title {
	font-weight: 400;
}

.card__subtitle {
	font-weight: 300;
}

.card__value {
	font-size: 1.1rem;
	font-weight: bold;
	text-align: center;
}

.card__label {
	font-size: 0.9rem;
	color: $grey-3;
	text-align: center;
	text-transform: uppercase;
}

.subtle-divider {
	margin: 2 * $spacingUnit;
	border-top: 1px solid $thin-line-color;
}

.syncing {
	animation-name: spin;
	animation-duration: 3000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.text-max-width {
	max-width: 200px;
	text-overflow: hidden;
}

@keyframes spin {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}

@media (max-width: $lg) {
	.main-content {
		margin-left: 0;
		padding: 2 * $spacingUnit;
	}
}

@media (max-width: $sm) {
	.main-content {
		padding: $spacingUnit;
	}

	.flex-row {
		flex-wrap: wrap;
		margin-top: 0;
		padding-top: 0;
	}

	.flex-item {
		margin: 2 * $spacingUnit 0;
	}
}
