$black: #000000;
$black-transparent: rgba(0, 0, 0, 0.25);
$white: #ffffff;
$grey-1: #262626;
// $grey-2:
$grey-3: #494949;
$grey-4: #666666;
// $grey-5:
$grey-6: #cccccc;
$grey-7: #e1e1e1;
// $grey-8:
// $grey-9:

$grey-dark: #1f1f1f;

$green-success: green;
$green-active: #52d869;
$red-error: red;

$dig-light-blue: #daecf3;
$dig-light-blue-40: rgba(218, 236, 243, 0.4);
$dig-light-blue-3: #d4eff3;
$dig-light-blue-4: #eef9fb;

$dig-red: #e75a5b;
$dig-red-2: #c64d4d;
$dig-red-transparent: rgba(231, 90, 91, 0.4);
$dig-red-transparent-2: rgba(231, 90, 91, 0.1);

$dig-ocean: #3bafda;
$dig-thunder: #233d4c;
$dig-light-pink: #f2ecee;
$dig-light-grey: #fbfbfb;
$dig-text: #262626;
$dig-border: #eeeeee;

$base-text-color: $grey-1;
$thin-line-color: #e6e9ed;
