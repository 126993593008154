.flex-row {
	display: flex;
	justify-content: space-between;
	margin: 1.875em 0;

	&.narrow {
		margin: 0.625em 0;
	}
}

.flex-item {
	flex: 1;
	margin: 0 0.625em;
	align-self: flex-start;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.flex-row-button {
	height: 40px;
	align-self: flex-end;
	margin-left: 1.875em !important;
	margin-bottom: 4px !important; // To align a button next to a 48px input/dropdown with label properly
}

.button-row {
	display: flex;
	justify-content: flex-end;
	min-height: 70px; // Makes the buttons on this row clear main actions on the page

	> button {
		margin: 0.625em;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

@media screen and (max-width: $lg) {
	.flex-row {
		flex-wrap: wrap;
		margin: 0;
	}

	.flex-item {
		min-width: 100%;
		margin: 1.875em 0;
	}
}
