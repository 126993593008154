.nav__root {
	display: flex;
}

.nav__drawer {
	width: $drawerWidth;
	flex-shrink: 0;
}

.nav__appbar {
	margin-left: $drawerWidth;
	height: $topNavHeight;
}

.nav__toolbar {
	padding: 0 4 * $spacingUnit;
	height: $topNavHeight;
	min-height: $topNavHeight !important;
	display: flex !important;
	justify-content: space-between;
}

.nav__menu-button {
	margin-right: 2 * $spacingUnit;
	display: none !important;
}

.nav__drawer-paper {
	width: $drawerWidth;
	margin-top: $topNavHeight;
}

.nav__content {
	flex-grow: 1;
	padding: 3 * $spacingUnit;
}

.nav__logo {
	width: 100px;
	height: 64px;
	margin: 20px auto;
}

@media (max-width: $xl) {
	.nav__appbar {
		width: 100%;
		z-index: 9999;
	}

	.nav__toolbar {
		padding: 0 2 * $spacingUnit;
	}
}

@media (max-width: $lg) {
	.nav__drawer-paper {
		margin-top: 0;
	}

	.nav__menu-button {
		display: block !important;
	}

	.nav__drawer .permanent {
		display: none;
	}
}
