*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir,
		Helvetica, sans-serif;
	font-weight: 300;
	color: $grey-1;
	background-color: $dig-light-grey;
	width: 100%;
	height: 100%;
	padding: 0 !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 {
	margin: 0;
	font-size: 2.5rem;
	font-weight: 300;
	line-height: 1;
}

h2 {
	margin: 0;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 1.333;
}
