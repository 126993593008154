$topNavHeight: 60px;
$drawerWidth: 280px;

$spacingUnit: 8px;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$ipad-height: 1024px;
$desktop-height: 1280px;
