.clients__dot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;

	&.inactive {
		background-color: $dig-red;
	}

	&.active {
		background-color: $green-active;
	}
}

.clients__switch-label {
	font-size: 0.9rem;
}
