.button {
	font-weight: 500;
	border-radius: 100px;
	border: 0.107em solid $dig-red !important;
	cursor: pointer;

	&:focus {
		box-shadow: none;
	}

	&:hover {
		transition: 0.3s ease 0s;
	}

	&:disabled {
		transition: none;
		cursor: not-allowed;
	}

	&:disabled:hover {
		transition: none !important;
	}

	&:focus {
		outline: none;
		box-shadow: 0px 0px 0px 1px $white, 0px 0px 0px 2px $dig-ocean,
			0 0 5px 2px $dig-ocean !important;
	}

	&.primary {
		background: $dig-red !important;
		color: $white !important;

		&:hover {
			background: $dig-red-2 !important;
		}

		&:disabled {
			background: $dig-red-transparent !important;
			border-color: $dig-red-transparent !important;
			opacity: 1;
		}

		&:disabled:hover {
			background: $dig-red-transparent !important;
		}
	}

	&.secondary {
		background: $white !important;
		color: $dig-red !important;

		&:hover {
			background: $dig-red-transparent-2 !important;
		}

		&:disabled {
			opacity: 0.4;
		}

		&:disabled:hover {
			background: white !important;
			opacity: 0.4;
		}
	}
}
